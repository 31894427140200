import React from 'react';
import type { PageProps } from 'gatsby';

import { AppState } from '../../../services/AppState';
import PageLayout from '../../../views/Layout/Page';
import { MetaTagsProvider } from '../../../views/MetaTags';
import SearchDetail from '../../../views/Search/SearchDetail';

export default function CarDetailPage({ params }: PageProps) {
  return (
    <MetaTagsProvider hideCanonical>
      <PageLayout
        hideMeta
        pageview="search_detail"
        preloadEnums={['equipments', 'fuel', 'type', 'transmission', 'color']}
        requiredState={AppState.LOADING}
      >
        <SearchDetail hash={params.hash} />
      </PageLayout>
    </MetaTagsProvider>
  );
}
